<template>
  <div class="bg-white p-4">
    <b-row no-gutters>
      <b-col cols="3" class="mr-2">
        <rm-input
          v-model="filterOptions.search"
          outlined
          placeholder="Search..."
        ></rm-input>
      </b-col>
      <b-col cols="3" class="d-flex">
        <rm-base-button
          class="mr-3"
          text="Search"
          @click="loadData"
        ></rm-base-button>
        <rm-base-button
          bgColor="danger"
          text="Clear Search"
          @click="clearFilter"
        ></rm-base-button>
      </b-col>
      <b-col cols="2">
        <rm-base-button
          @click="toggleFilter"
          class="ml-2"
          :text="`${showFilter ? 'Hide' : 'Show'} Filters`"
          bgColor="primary--light"
          textColor="primary"
          ><rm-base-icon slot="post-icon" class="ml-3" name="filter-icon"
        /></rm-base-button>
      </b-col>
      <!-- <b-col cols="2">
        <div class="">
          <rm-base-button
            class="ml-auto"
            text="Create User"
            bgColor="primary"
            textColor="white"
            v-b-modal.modal-lg-create-user
            ><rm-base-icon slot="post-icon" class="ml-2" name="plus-icon"
          /></rm-base-button>
        </div>
      </b-col> -->
      <!-- <b-col cols="2">
        <div class="d-flex w-100">
          <rm-base-button
            @click="
              exportData({
                items,
                config: {},
                title: 'Clients Report',
              })
            "
            class="ml-auto"
            text="Export"
            bgColor="primary"
            textColor="white"
            ><rm-base-icon slot="post-icon" class="ml-2" name="export-icon"
          /></rm-base-button>
        </div>
      </b-col> -->
    </b-row>
    <b-row v-if="showFilter" class="mb-5">
      <b-col cols="2" class="bordered ml-3">
        <rm-drop-down
          v-model="filterOptions.marital_status"
          item-text="text"
          item-value="value"
          outlined
          label="Marital Status"
          :options="maritalStatusOptions"
        />
      </b-col>

      <b-col cols="2" class="ml-2">
        <rm-drop-down
          v-model="filterOptions.gender"
          outlined
          label="Gender"
          :options="genderOptions"
        />
      </b-col>

      <b-col cols="12" class="d-flex">
        <rm-base-button
          class="ml-3"
          text="Filter"
          bgColor="tertiary"
          textColor="white"
          @click="loadData"
        ></rm-base-button>
        <rm-base-button
          class="ml-3"
          text="Clear Filter"
          bgColor="tertiary--light"
          textColor="tertiary"
          @click="clearFilter"
        ></rm-base-button>
      </b-col>
    </b-row>

    <b-table
      ref="clientsTable"
      thead-class="bg-gray-400 text-uppercase text-sm-2"
      tbody-class="text-sm"
      :selectable="true"
      hover
      :busy="isLoading"
      :items="clients"
      :fields="fields"
      @row-selected="onRowSelected"
    >
      <template #table-busy>
        <div class="text-center text-gray my-5">
          <b-spinner class="align-middle"></b-spinner> <br />
          <strong>Loading Data...</strong>
        </div>
      </template>
      <template #head(check)="">
        <input
          type="checkbox"
          :checked="selectAll && clients.length == selected.length"
          @click="selectAll = !selectAll"
        />
      </template>
      <template v-slot:cell(check)="{ rowSelected, index }">
        <div class="d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            :checked="rowSelected"
            @click="handleRowSelect(rowSelected, index)"
          />
        </div>
      </template>
      <template #cell(customer_name)="{ item }">
        <div
          class="text-primary pointer"
          @click="globalRouter('clients.profile', { id: item.id })"
        >
          {{ `${item.customer_name}` }}
        </div>
      </template>
      <template #cell(status)="{ item }">
        <div
          class="text-center px-3 py-1 rounded"
          :class="item.status == 'active' ? 'inactive' : 'active'"
        >
          <small>{{ item.status }}</small>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="paging.totalRows"
      v-model="paging.currentPage"
      :total-rows="paging.totalRows"
      :per-page="paging.perPage"
      aria-controls="clientsTable"
      @change="changePage"
    />

    <!-- <rm-base-modal id="create-user" size="lg">
      <rm-create-user @userCreated="fetchClients()" />
    </rm-base-modal> -->
  </div>
</template>

<script>
// import RmCreateUser from "./RmCreateUser";
import formatDate from '@/mixins/formatDate.js';
import exportData from '@/mixins/exportData.js';
// import States from "../../../utils/countries.json";

export default {
  data() {
    return {
      isLoading: false,
      selectAll: false,
      showFilter: false,
      clients: [],
      selected: [],
      paging: {
        currentPage: 1,
        totalRows: null,
        perPage: null,
      },
      filterOptions: {
        search: null,
        marital_status: null,
        gender: null,
      },
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: 'check',
          label: '',
          class: 'text-center',
        },
        {
          key: 'customer_name',
          label: 'Customer Name',
          class: 'text-center',
        },
        {
          key: 'referrer',
          sortable: false,
          label: 'Referrer',
          class: 'text-center',
        },
        {
          key: 'email',
          sortable: false,
          label: 'Email',
          class: 'text-center',
        },
        {
          key: 'phone_number',
          sortable: false,
          label: 'Phone Number',
          class: 'text-center',
        },
        {
          key: 'wallet_balance',
          sortable: false,
          label: 'Wallet Balance',
          class: 'text-center',
        },
        {
          key: 'status',
          sortable: false,
          label: 'Status',
          class: 'text-center',
        },
        {
          key: 'date_registered',
          sortable: false,
          label: 'Reg Date',
        },
      ],
      maritalStatusOptions: [
        {
          text: 'Single',
          value: 'single',
        },
        {
          text: 'Married',
          value: 'married',
        },
        {
          text: 'Divorced',
          value: 'divorced',
        },
      ],
      genderOptions: ['male', 'female'],
    };
  },
  computed: {},
  components: {
    // RmCreateUser,
  },
  created() {
    this.loadData();
  },
  mixins: [formatDate, exportData],
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.clientsTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.clientsTable.clearSelected();
    },
    selectRow(index) {
      this.$refs.clientsTable.selectRow(index);
    },
    unSelectRow(index) {
      this.$refs.clientsTable.unselectRow(index);
    },
    handleRowSelect(isRowSelected, index) {
      if (!isRowSelected) {
        this.selectRow(index);
      } else {
        this.unSelectRow(index);
      }
    },
    changePage(pageNo) {
      this.paging.currentPage = pageNo;
      this.loadData();
    },
    clearFilter() {
      this.filterOptions = {
        search: null,
        marital_status: null,
        gender: null,
      };

      this.loadData();
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
      if (!this.showFilter) {
        this.filterOptions = {
          search: null,
          marital_status: null,
          gender: null,
        };
      }
    },
    async loadData() {
      this.isLoading = true;
      try {
        const params = {
          ...this.filterOptions,
          page: this.paging.currentPage,
        };
        await this.fetchClients(params);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchClients(params) {
      const { paging, rows } = await this.$store.dispatch(
        'clients/fetchCustomers',
        params
      );

      this.clients = rows.map((item) => {
        const { subscriptions, wallet, upline } = item;
        return {
          id: item.id,
          customer_name: `${item.first_name} ${item.last_name}`,
          referrer: `${upline.first_name} ${upline.last_name}`,
          email: item.email,
          phone_number: item.phone_number,
          wallet_balance: `₦${this.$formatCurrency(wallet?.balance / 100)}`,
          status:
            subscriptions && subscriptions.length > 0
              ? 'active'
              : 'inactive',
          date_registered: this.formatDate(item.created_at),
        };
      });

      this.paging.currentPage = paging.currentPageNo;
      this.paging.totalRows = paging.total;
      this.paging.perPage = paging.perPage;
    },
  },
  watch: {
    selectAll(v) {
      if (!v) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.active {
  background-color: #fff5e9;
  color: #ec5800;
}
.inactive {
  background-color: #e8fbf4;
  color: #1d9067;
}
</style>
