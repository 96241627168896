<template>
  <div>
    <!-- <b-row class="mt-2">
      <b-col cols="2">
        <rm-drop-down outlined :options="metricOptions" />
      </b-col>
      <b-col cols="10" />
      <b-col cols="3" v-for="(metric, ix) in clientMetrics" :key="ix">
        <rm-metric-card :metric="metric" />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between">
      <rm-new-clients-chart />
      <rm-country-chart class="mx-4" />
      <rm-age-chart />
    </div> -->
    <b-row class="mt-5">
      <b-col cols="12">
        <rm-clients-table />
      </b-col>
      <b-col cols="4"> </b-col>
      <b-col cols="4"> </b-col>
    </b-row>
  </div>
</template>

<script>
// import RmAgeChart from "./components/RmAgeChart.vue";
import RmClientsTable from "./components/RmClientsTable.vue";
// import RmCountryChart from "./components/RmCountryChart.vue";
// import RmMetricCard from "@/components/global/RmMetricCard.vue";
// import RmNewClientsChart from "./components/RmNewClientsChart.vue";

export default {
  components: {
    // RmMetricCard,
    // RmNewClientsChart,
    // RmCountryChart,
    // RmAgeChart,
    RmClientsTable,
  },
  data: () => ({
    metricOptions: ["All Time"],
    clientMetrics: [
      {
        name: "Total Clients",
        amount: "25K",
        growth: +100,
        icon: "total-clients",
      },
      {
        name: "Active Clients",
        amount: "250",
        growth: +15,
        icon: "active-clients",
      },
      {
        name: "Inactive Clients",
        amount: "1000",
        growth: +100,
        icon: "inactive-clients",
      },
      {
        name: "Prospective Clients",
        amount: "N5.2M",
        growth: -22.5,
        icon: "prospective-clients",
      },
    ],
  }),
};
</script>
